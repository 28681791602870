// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

const feather = require('feather-icons')

$(document).on('turbolinks:load', function() {

  // SCROLL TO TOP
  function scrollCheck() {
    if ($(window).scrollTop() > 100) { $('#top').css('display', 'block'); }
    else { $('#top').css('display', 'none'); }
  }

  $(window).scroll(function() { scrollCheck(); });

  $('#top').click(function() { $(window).scrollTop(0); });

  feather.replace();

  // PARALLAX
  function parallax() {
    var plxWindow = $('#js-parallax-window');

    if (plxWindow.length) {
      var plxBackground = $('#js-parallax-background');
      var plxWindowTopToPageTop = $(plxWindow).offset().top;
      var windowTopToPageTop = $(window).scrollTop();
      var plxWindowTopToWindowTop = plxWindowTopToPageTop - windowTopToPageTop;
      var plxSpeed = 0.35;
      plxBackground.css('top', - (plxWindowTopToWindowTop * plxSpeed) + 'px');
    }
  }

  parallax();

  $(window).scroll(function() { parallax(); });

  // MENU
  function toggleMenu() {
    $('.nav').toggleClass('closed');
    $('#close-trigger').toggleClass('closed');
  }

  $('#menu-trigger').click(function() {
    toggleMenu();
    $('body').css('overflow', 'hidden');
    $('html').css('overflow', 'hidden');
  });

  $('#close-trigger').click(function() {
    toggleMenu();
    $('body').css('overflow', 'auto');
    $('html').css('overflow', 'auto');
  });
});
